import React, { useEffect, useState } from "react"

function TermsText() {

    const privacyUrl = "https://www.inspectionsupport.com/privacy/"

    return (
        <div>
            <br />
            <br />
            <br />
            <h1 style={{ textAlign: "center",fontWeight:'bold',fontSize:'24px',marginTop:"40px"}}>Terms of Service - Inspection Support Network</h1>
            <br/>
            <h5>Effective Date: April 5th, 2021</h5>
            <br/>
            <p>
              Hello, and thanks for visiting us at ISN. These terms (the
              “Terms”) explain the rights, roles and responsibilities between
              us. As explained below,
              <strong>
                <em>
                   
                  these Terms mandate arbitration and also prohibit class action
                  relief
                </em>
              </strong> 
              involving any dispute or claim. Please read them carefully. 
            </p>
            <p>
              To make it easier to understand what we are talking about, here
              are some definitions.
            </p>
            <p>
              We are Inspection Support LLC. We are referred to in the Terms as
              &#8220;ISN,&#8221; &#8220;we,&#8221; &#8220;us&#8221; or “our,”
              and these references also include ISN’s suppliers when they act on
              our behalf to deliver the Services.
            </p>
            <p>
              Any reference to “Inspector,” &#8220;you&#8221; and
              &#8220;your&#8221; in the Terms refers to you, any legally
              recognized entity that is the account subscriber to the Services
              and, as the context requires with respect to use and access of the
              Services, the individual authorized users under your account
              (“Users”).
            </p>
            <p>
              The Terms cover your use of the inspectionsupport.com website, any
              mobile applications we provide, and other web and digital
              properties of ours, plus any related telephone services. These are
              individually and collectively referred to as the
              &#8220;Properties.&#8221; 
            </p>
            <p>
              The inspection support software tools and other services offered
              by ISN through paid subscriptions are referred to as the “ISN
              Subscription Services.” The ISN Subscription Services, any other
              tools and services we make available through the Properties, and
              the Properties are individually and collectively referred to as
              the “Services.”
            </p>

            <ol>
              <li  style={{ fontSize: "16px" }}>
                <h3 style={{ fontSize: "18px" }}> Consent to Terms; Changes to Terms.</h3>

                <ol type="a" style={{ listStyle: "lower-alpha" }}>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Consent and Agreement to Terms. </u>
                    </strong>
                    By accessing and using any Services, you acknowledge that
                    you have read, understood, and agree to be bound by these
                    Terms, the 
                    <a href={privacyUrl}>
                      ISN Privacy Policy
                    </a>
                     (which describes our collection, use and disclosure to third parties, including our corporate affiliate Porch.com, Inc., of personal information in connection with your use of the Services), and any applicable Other Program Terms (described below).
                    To access and use any Services you must be at least 18 years
                    old (or the age of majority in your state, if different) and
                    fully competent to agree to contract.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Changes to Terms.</u>
                    </strong>
                    We can change the Terms at any time by posting an updated
                    version. Any modifications to the Terms will be effective 30
                    days from the date of posting. Please review the Terms from
                    time to time because, by using the Services after the
                    effective date of such changes, you are agreeing to the
                    changes. You can determine if the Terms have been revised
                    since your last visit by referring to the “Effective Date”
                    date at the top.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Other Program Terms.</u>
                    </strong>
                    ISN sometimes partners with corporate affiliates and other
                    selected companies (collectively, “ISN Partners”). ISN Partners help us
                    expand the range of available products and services
                    supporting your home inspection professional businesses. A
                    list of current ISN Partners is located on the 
                    <a href="https://www.inspectionsupport.com/partners/">
                      ISN Partner Page
                    </a>
                    .
                    <br />
                    We offer additional programs through or with the Services,
                    either alone or with ISN Partners. If you elect to
                    participate in any of these programs, we or the ISN Partner
                    may communicate any additional terms and conditions for the
                    program (“Other Subscription Terms”) to you.
                    <br />
                    If there is any conflict between Other Program Terms and any
                    of these Terms, the Other Program Terms will govern.
                  </li>
                </ol>
              </li>

              <li  style={{ fontSize: "16px" }}>
                <h3 style={{ fontSize: "18px" }}>
                   
                  Subscription Term; Termination; Accounts; Charges; Access. 
                </h3>
                <ol type="a" style={{ listStyle: "lower-alpha" }}>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                      <u>Subscription Term</u>
                    </strong>
                    . Your subscription for ISN Subscription Services begins on
                    the first day you register your ISN Subscription Services
                    account and continues month-to-month until one of us
                    terminates using the process below.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Termination</u>
                    </strong>
                    . Either of us can terminate the Services and/or your
                    account at any time, with or without cause. You can
                    terminate by emailing us at 
                    <a href="mailto:help@inspectionsupport.com">
                      help@inspectionsupport.com
                    </a> 
                    with your request to terminate. If we terminate your account
                    or your access to the Services, we will notify you by email
                    or letter. Unless otherwise agreed by the parties,
                    termination is effective as of the date the terminating
                    party provides its notice of termination.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Account and User Registration; Communications</u>
                    </strong>
                    . You must create an account as an account owner before you
                    can add Users. You must keep all of your account and User
                    information accurate and complete, and secure from
                    unauthorized access and misuse. You are responsible for all
                    activity in connection with your account.
                    <br />
                    You agree that communication may be made with you or your
                    Users by telephone, email, text (SMS) message or other
                    electronic or non-electronic means, at any of the contact
                    numbers or addresses you provide, as part of the normal
                    business operation of the Services.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Charges</u>
                    </strong>
                    . ISN will charge you for your ISN Subscription Services in
                    relation to each individual inspection, including the
                    monthly minimum, if any (&#8220;Inspection Charges&#8221;).
                    ISN also may also charge you for certain other services or
                    products that you choose to purchase (&#8220;ISN
                    Charges&#8221;) in addition to your Inspection Charges
                    (collectively, &#8220;Charges&#8221;).
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Payment</u>
                    </strong>
                    . Your fees for ISN Subscription Services are generally
                    billed in arrears. Payments for Inspection Charges,
                    including the monthly minimum (if any), are due and payable
                    to ISN on the 10<sup>th</sup> of the month following the
                    month in which the Inspection Charges were incurred.
                    Payments for other Charges are due and payable in accordance
                    with the payment terms communicated with the applicable
                    Service. All Charges are non-refundable unless otherwise
                    explained in these Terms. We typically require payments by
                    credit card or other valid payment card.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Returns; Refunds</u>
                    </strong>
                    . Generally, if we agree to refund a Charge, the Charge
                    amount will be credited to your ISN account. Payments for
                    Charges that meet our requirements for a refund rather than
                    an account credit (per these Terms or otherwise in our
                    discretion) will be refunded back to the original method of
                    payment.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Inactive Accounts</u>
                    </strong>
                    . We may terminate any account that remains inactive for one
                    year or more, which also will terminate any accumulated
                    credits, incentives, rewards or the like associated with the
                    account.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Services Modifications.</u>
                    </strong>
                    In our sole discretion we can enhance, reduce, discontinue,
                    or restrict access to the Services (or any portion of the
                    Services), temporarily or permanently. We will provide
                    timely notice of such changes whenever practicable.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Denial of Services.</u>
                    </strong>
                    ISN may refuse to provide the Services to anyone, terminate
                    your account or any access to or use of the Services,
                    entirely or partially, and/or limit or prevent any User’s
                    access to and use of the Services, at any time and for any
                    reason.
                  </li>
                </ol>
              </li>

              <li  style={{ fontSize: "16px" }}>
                <h3 style={{ fontSize: "18px" }}>Communication Consents. </h3>
                <ol type="a" style={{ listStyle: "lower-alpha" }}>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Monitoring and Recording.</u>
                    </strong>
                    <p>
                      When you contact us or when we contact you through the
                      Services, we may monitor and/or record those
                      communications to assure continuing quality, customer
                      satisfaction and other legitimate business purposes. Calls
                      between you and your Users that originate with a transfer
                      to a partner service (including any ISN Partner) may also
                      be monitored and recorded.
                    </p>
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Emails and Automated Calls/Texts</u>
                    </strong>
                    . You consent to receive emails, autodialed, pre-recorded or
                    artificial voice calls and text (SMS) messages from ISN or
                    ISN Partners related to the Services and ISN Partner
                    products or services that might interest you. These emails,
                    calls or texts may be made to any email address or phone
                    number you or your Users provide, even if a number is
                    registered on any state or federal Do Not Call list.
                    Agreeing to receive marketing communications is not a
                    condition of purchasing any products or services of ISN or
                    ISN Partners.
                    <p>
                      For details on our use of your and your clients’ personal information, and how to opt
                      out of receiving ISN marketing communications, please see the
                        
                      <a href={privacyUrl}>
                        <strong>ISN Privacy Policy</strong>
                      </a>
                      ). For details on how to opt out of receiving ISN Partner
                      marketing communications, please see the privacy policy of
                      the ISN Partner that contacted you or your Users.
                    </p>
                    <p>
                      We appreciate your feedback, so let us know at 
                      <a href="mailto:help@inspectionsupport.com">
                        help@inspectionsupport.com
                      </a> 
                      if you are being contacted on our behalf against your
                      wishes.
                    </p>
                  </li>
                </ol>
              </li>

              <li  style={{ fontSize: "16px" }}>
                <h3 style={{ fontSize: "18px" }}>Content; License; Permissible Use.</h3>
                <ol type="a" style={{ listStyle: "lower-alpha" }}>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Grant of Limited License.</u>
                    </strong>
                    <p>
                      We grant to you and your Users a limited, revocable,
                      non-exclusive, non-transferable and non-sublicensable
                      right to access and use the Services in connection with,
                      and to support and facilitate, providing home inspection
                      services to your clients, subject to your compliance with
                      the Terms.
                    </p>
                  </li>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>ISN Content.</u>
                    </strong>
                    The Services, all materials, content and information therein
                    and their selection, arrangement and composition, and all
                    trademarks, service marks or other brands or names of ISN
                    (individually and collectively, &#8220;ISN’s Content&#8221;)
                    are the proprietary property of ISN and its suppliers and
                    licensors and are protected by United States and
                    international intellectual property laws, including
                    trademark and copyright laws. You may not remove, alter or
                    obscure any copyright, trademark or proprietary rights
                    notice incorporated in ISN’s Content or accompanying the
                    Services. Except for the limited license we provide above,
                    we do not provide to you or any User any other license,
                    whether implied, by estoppel or otherwise, and all of our
                    other rights are explicitly reserved to ISN.
                    <p>
                      We do not warrant or represent that any of ISN’s Content,
                      or any Third-Party Materials (defined below) contained in,
                      linked to or used with the Services, are accurate,
                      complete or suitable.
                    </p>
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>No Reverse Engineering.</u>
                    </strong>
                    <p>
                      You may not reverse engineer, decompile, disassemble or
                      otherwise attempt to derive the source code or
                      architectural framework for the Services (except to the
                      extent applicable law permits).
                    </p>
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>No Automated Queries; No Scraping or Harvesting.</u>
                    </strong>
                    <p>
                      You may not access any of the Services through automated
                      or artificial means (including screen and database
                      scraping, spiders, robots, crawlers, deep-link, or any
                      similar or equivalent automatic or manual process), or in
                      any way obtain or attempt to obtain any of ISN’s Content
                      or any User Content (defined below) using any means that
                      we didn’t intentionally make available through the
                      Services.
                    </p>
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Confidentiality.</u>
                    </strong>
                    If you receive confidential information from ISN, until such
                    information is no longer confidential, you agree not to use
                    it or disclose it to any third party except as necessary to
                    perform your obligations or exercise your rights
                    contemplated by our relationship. Confidential information
                    includes but is not limited to information concerning ISN’s
                    unpublished fees, marketing plans, financial results,
                    unpublished pricing schedules, product lines, product plans,
                    proprietary technology, research information, practices,
                    trade secrets, and any and all other information that is not
                    generally known to the public.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>No Improper Uses</u>
                    </strong>
                    . You may not (i) frame any part of the Service; (ii)
                    access, use, or attempt to use, the Services in any way that
                    we didn’t intentionally make available; (iii) damage,
                    disable, overburden, or impair any of the Services or
                    interfere with the rights of or otherwise harm ISN, other
                    inspectors and their respective users, or any other person
                    or entity; (iv) commercially sell, resell, license,
                    sublicense, or distribute the Services to a third party; or
                    (vi) use the Services in ways that otherwise violate the
                    Terms.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>No Objectionable Conduct</u>
                    </strong>
                    . You may not use the Services to (i) harass anyone,
                    including by threatening, defaming, or making racist,
                    obscene, or other offensive statements to them; (ii)
                    infringe or violate anyone’s privacy, property or other
                    rights; (iii) deceive or defraud anyone; or (iv) violate any
                    applicable law or regulation.
                  </li>
                </ol>
              </li>

              <li  style={{ fontSize: "16px" }}>
                <h3 style={{ fontSize: "18px" }}>User Content. </h3>
                <ol type="a" style={{ listStyle: "lower-alpha" }}>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Definition of User Content.</u>
                    </strong>
                    The data, information, reports, templates, or other materials that you, your Users or anyone on your behalf creates, uploads, links, posts, publishes, sends or otherwise provides to or with the Services are referred to, individually and collectively, as "User Content.” User Content includes personal information of your clients and other individuals that you provide to ISN.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Responsibility for User Content.</u>
                    </strong>
                    You (not ISN) are solely responsible for User Content and any consequences of using, submitting and transmitting User Content through the Services, including with respect to any person whose personal information, reports, templates or other materials are uploaded, linked or otherwise provided to or with the Services by you, your Users or anyone on your behalf or who relies on any User Content as information or advice. With respect to all User Content connected with your account, you represent and warrant that you have received all required consents to provide such User Content to ISN and that the use and disclosure of such User Content in accordance with the Terms and the  
                    <a href={privacyUrl}>
                      ISN Privacy Policy
                    </a>
                      does not contradict any agreement, promise, representation or warranty you or your Users have made to any person who owns, or whose information (including personal information) is included in, such User Content.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>License to User Content.</u>
                    </strong>
                    <p>We do not own User Content or claim any ownership in it. </p>
                    <p>We will use and disclose User Content as permitted in the Terms and the ISN Privacy Policy. You grant ISN a worldwide, royalty-free, irrevocable, perpetual, sub-licensable and transferable license to use, copy, distribute, disclose, prepare derivative works of, perform and display the User Content for those purposes, subject to our obligations under the ISN Privacy Policy and any applicable laws relating to personal information. This license extends to ISN Partners, ISN’s corporate affiliates and other third parties only as described in the ISN Privacy Policy. This license is non-exclusive, so you can use or post your User Content elsewhere and give these rights to others if you want. </p>
                    <p>You also acknowledge and agree that ISN may create, generate and use anonymized sets of data derived from the content submitted by multiple ISN users, including your User Content (“Aggregate Data”) for any lawful purpose, and that ISN retains all right, title, and interest in Aggregate Data.  Please see the ISN Privacy Policy for additional information on ISN’s use and disclosure of Aggregate Data.  </p>
                    <p>If you are a franchisee, some of the User Content may be owned by the franchisor and your current or future use of the User Content may be subject to conditions and limitations. You should consult your franchise agreement regarding ownership and other terms and conditions of the franchise governing User Content.</p>

                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>No Review of User Content.</u>
                    </strong>
                    We have no obligation to pre-screen or review, and we do not approve, control or endorse, any User Content. However, we reserve the right to refuse, remove or modify User Content for any reason. 
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Feedback</u>
                    </strong>
                    . If you or your Users choose to give or make available to ISN any suggestions, comments, ideas, improvements or other feedback relating to the Services (“Feedback”), ISN will be free to use, disclose, reproduce, have made, modify, license, transfer and otherwise utilize and distribute your Feedback in any manner, without credit or compensation to you or any Users.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                      <u>Download of User Content</u>.
                    </strong>
                    <p>
                    The Services include a feature that permits you, at any time during the Term, to download the User Content in your account (subject to any limitations that the account owner places on Users). Once your account is terminated, ISN has no obligation to retain or provide any User Content to you.
                    </p>
                  </li>
                </ol>
              </li>

              <li  style={{ fontSize: "16px" }}>
                <h3 style={{ fontSize: "18px" }}>Third-Party Intellectual Property.</h3>
                <ol type="a" style={{ listStyle: "lower-alpha" }}>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Third Party Materials and Intellectual Property.</u>
                    </strong>
                    <p>
                    The Services contain links to third-party websites, advertisements, services, offers, or other content (collectively, "Third-Party Materials"), and Third-Party Materials may be provided on or linked to from the Services. Any Third-Party Materials are not owned or controlled by ISN, so we are not responsible for them and we do not endorse, sponsor or recommend them. All trademarks, product names and logos of any third parties that appear on the Services are property of their respective owners.
                    </p>
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Claims of Copyright Infringement.</u> 
                    </strong>
                    <p>
                    f you believe that any copyright in work you own has been infringed because it is reproduced in connection with the Services, you can notify us pursuant to the Digital Millennium Copyright Act ("DMCA") by sending a written notice to ISN at the address below. Your DMCA notice must include the following information: (i) a physical or electronic signature of the person authorized to act on behalf of the owner of the copyright, (ii) identification of the copyrighted work claimed as infringed, (iii) a description of where the claimed infringing material is located in the Services, (iv) reasonably sufficient information for us to contact you, such as an address, telephone number, and, if available, an e-mail address, (v) a signed statement that you believe in good faith belief that use of the material in the manner described is not authorized by the copyright owner, its agent, or the law, and (vi) a signed statement that the information in the notification is accurate, and under penalty of perjury, that you are the copyright owner or you are authorized to act on behalf of the owner.  <br />
                      <br />
                      Notification pursuant to the DMCA should be submitted to 
                      <a href="mailto:help@inspectionsupport.com">
                        help@inspectionsupport.com
                      </a>
                      <br />
                      or:
                      <p>
                        <br />
                        Inspection Support LLC
                        <br />
                        Attention: ISN Legal Department
                        <br />
                        2200 1st Avenue South<br />
                        Seattle WA 98134
                      </p>
                    </p>
                  </li>
                </ol>
              </li>

              <li  style={{ fontSize: "16px" }}>
                <h3 style={{ fontSize: "18px" }}>Liability.</h3>
                <ol type="a" style={{ listStyle: "lower-alpha" }}>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Warranty Disclaimer</u>. 
                      <em>
                      The Services are provided "as is" without warranties of any kind, either express or implied. To the fullest extent the law permits, ISN disclaims all warranties, express, implied, statutory, and otherwise, in connection with the Services, including any implied warranties of title, merchantability, fitness for a particular purpose, satisfactory quality, security, accuracy, availability, use of reasonable care and skill, and non-infringement, as well as any warranties arising by usage of trade, course of dealing, and course of performance.
                      </em>
                    </strong>
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Limitation of Liability</u>. 
                      <em>
                      In no event will ISN, ISN Partners, its and their affiliated companies, and their respective officers, directors, employees, contractors, shareholders, agents, representatives, licensors, licensees and suppliers (the “ISN Parties”) be liable to you or any third party whatsoever for any damages for cost of cover, lost profits or other direct, indirect, incidental, special, punitive, or consequential damages arising out of your use of the Services or your transactions with ISN or any ISN Partner, whether based on warranty, contract, tort, negligence or any other legal theory, whether or not such damages are foreseeable and whether or not any ISN Party is advised of the possibility of such damages. The foregoing limitation of liability shall apply to the fullest extent the law permits in any applicable jurisdiction.
                      </em>
                    </strong>

                    <p>
                      <strong>
                        <em>
                        The collective liability of the ISN Parties arising out of or connected with the Terms, the Services, and your transactions with ISN or any ISN Partner will not exceed (i) the amount you have paid to ISN for use of the applicable Services in the preceding 12-month period or (ii) if you did not pay ISN, then one hundred U.S. dollars (USD$100.00).
                        </em>
                      </strong>
                    </p>
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Limitations Period</u>. 
                      <em>
                      ou must bring any claims relating to the Services or the Terms within one (1) year from the date the cause of action arose. Claims brought after such period will be VOID.
                      </em>
                    </strong>
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Indemnification</u>. 
                      <em>
                      You agree to indemnify, defend and hold harmless the ISN Parties from and against any claims, losses, liabilities, expenses, damages, penalties and costs (including reasonable attorneys’ fees) incurred by the ISN Parties as a result of (i) your and your Users’ transactions with your clients or other third parties, (ii) any false or inaccurate User Content, or (iii) any violation by you or your Users of applicable law or any of your obligations or representations and warranties under the Terms. We have the exclusive right to choose counsel to defend any such claim.
                      </em>
                    </strong>
                  </li>
                </ol>
              </li>

              <li  style={{ fontSize: "16px" }}>
                <h3 style={{ fontSize: "18px" }}>Disputes.</h3>
                <ol type="a" style={{ listStyle: "lower-alpha" }}>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                      <u>Binding Arbitration</u>
                      <em>
                        . You agree that any dispute or claim arising out of or
                        relating in any way to the Terms, your use of the
                        Services or any relationship between us, including the
                        validity, applicability or interpretation of the Terms
                        (any of these, a “Dispute”) will be resolved only by
                        binding arbitration rather than in court. There is no
                        judge or jury in arbitration, and court review of an
                        arbitration award is limited.
                      </em>
                    </strong>
                    <br />
                    <br />
                    The arbitration will be conducted by the American
                    Arbitration Association (AAA) under its then-applicable
                    rules, including (as appropriate) its Supplementary
                    Procedures for Consumer-Related Disputes. The AAA&#8217;s
                    rules are available at http://www.adr.org/. Payment of all
                    filing, administration and arbitrator fees will be governed
                    by the AAA’s rules. The arbitration shall be conducted in
                    the English language by a single independent and neutral
                    arbitrator. For any hearing conducted in person as part of
                    the arbitration, you agree that the hearing will be
                    conducted in Las Vegas, Nevada within the United States.
                    Without limiting the jurisdiction of any other court, you
                    admit and further irrevocably agree to submit to the
                    personal jurisdiction of the courts located within Clark
                    County, Nevada for the purpose of entering judgments on
                    arbitral awards.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Class Action Waiver</u>
                      <em>
                        . Any Dispute will be conducted only on an individual
                        basis and not in a class, consolidated or representative
                        action or arbitration or as a named or unnamed member in
                        a class, consolidated, representative or private
                        attorney general legal action. Your continued use of the
                        Services signifies your explicit consent to this waiver.
                      </em>
                    </strong>
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Venue</u>
                    </strong>
                    . If for any reason a claim proceeds in court rather than in
                    arbitration, we each waive any right to a jury trial and
                    agree that such claim shall be brought only in courts of
                    competent jurisdiction located in Clark County, Nevada. You
                    agree and submit to the personal jurisdiction and venue of
                    such courts and waive any objection on the grounds of venue,
                    forum non conveniens or any similar grounds. Regardless, you
                    and ISN may seek injunctive relief and any other equitable
                    remedies from any court of competent jurisdiction to protect
                    intellectual property rights, existing, pending or
                    otherwise, and independent of resolving any Dispute as
                    required by arbitration.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Governing Law</u>
                    </strong>
                    . You agree that the Terms and any Dispute will be governed
                    solely by United States federal law and Nevada state law,
                    without regard to any conflict of law provisions.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Attorneys’ Fees</u>
                    </strong>
                    . If any of the ISN Parties take legal action against you as
                    a result of your violation of these Terms, or in the event
                    of any Dispute, the ISN Parties will be entitled to recover
                    from you, and you agree to pay, all reasonable
                    attorneys&#8217; fees and costs of such action, in addition
                    to any other relief granted to the ISN Parties.
                  </li>
                </ol>
              </li>

              <li  style={{ fontSize: "16px" }}>
                <h3 style={{ fontSize: "18px" }}>General.</h3>
                <ol type="a" style={{ listStyle: "lower-alpha" }}>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Assignment</u>
                    </strong>
                    . The Terms, and any rights and licenses granted hereunder,
                    may be transferred or assigned by you only with ISN&#8217;s
                    prior written consent, but may be assigned by ISN without
                    restriction and without notice to you.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Entire Agreement and Severability</u>
                    </strong>
                    . The Terms, the Privacy Policy and any applicable Other
                    Program Terms are the entire agreement between you and ISN
                    concerning the Services. If any provision is held to be
                    invalid, void, or unenforceable by a court or other duly
                    authorized tribunal with competent jurisdiction, such
                    provision shall be limited or eliminated to the minimum
                    extent necessary and replaced with a valid provision that
                    most embodies the intent, and the remaining provisions shall
                    remain in full force and effect.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Waiver</u>
                    </strong>
                    . No waiver of any provision shall be treated as any further
                    or continuing waiver of such provision or any other
                    provision, and ISN&#8217;s inaction or lack of assertion of
                    any right or provision does not constitute a waiver of such
                    right or provision.
                  </li>
                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Third Party Beneficiaries.</u>
                    </strong>
                    Nothing in the Terms create any right of action on the part
                    of any third party, except (i) for the ISN Parties and
                    suppliers or others who are party to written agreements with
                    us that specifically provide them third party beneficiary
                    rights and (ii) as expressly provided in the applicable
                    Other Program Terms.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Export Control</u>
                    </strong>
                    . Software and the transmission of applicable technical
                    data, if any, in connection with the Services may be subject
                    to export control regulations. You agree to comply with all
                    applicable laws regarding software and the transmission of
                    technical data exported from the United States or the
                    country in which you reside.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Survival</u>
                    </strong>
                    . All provisions of the Terms that by their nature
                    reasonably should survive termination shall so survive.
                  </li>

                  <li style={{ fontSize: "16px" }}>
                    <strong>
                       
                      <u>Contact Us</u>
                    </strong>
                    . If you have questions about the Terms or the Privacy
                    Policy, please contact us by email at 
                    <a href="mailto:help@inspectionsupport.com">
                      help@inspectionsupport.com
                    </a> 
                    or in writing at Inspection Support LLC, Attention: ISN
                    Legal Department, 2200 1st Avenue S, Seattle WA 98134. We
                    will respond as quickly as possible.
                  </li>
                </ol>
              </li>
            </ol>
        </div>
    )
}

export default TermsText